import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { localize } from 'utils/Localization';
import useLanguage from 'hooks/useLanguage';

const Text = ({ textKey }) => {
  const { language } = useLanguage();

  const localizedText = useMemo(() => localize(textKey, language), [textKey, language]);

  return <span>{localizedText}</span>;
};

Text.propTypes = {
  textKey: PropTypes.string.isRequired,
};

export default Text;

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Text from 'components/Text';
import CardBody from 'components/base/CardBody';
import Card from 'components/base/Card';
import Button from 'components/base/Button';

import { keys } from 'utils/Localization';

const shanghaiAttractions = [
  {
    name: keys.THE_BUND,
    description: keys.THE_BUND_DESC,
    img: '/content/attractions/thebund.jpg',
  },
  {
    name: keys.YUYUAN_GARDEN,
    description: keys.YUYUAN_GARDEN_DESC,
    img: '/content/attractions/yuyuangarden.jpg',
  },
  {
    name: keys.JADE_BUDDHA_TEMPLE,
    description: keys.JADE_BUDDHA_TEMPLE_DESC,
    img: '/content/attractions/jadebuddhatemple.jpg',
  },
  {
    name: keys.DISNEYLAND,
    description: keys.DISNEYLAND_DESC,
    img: '/content/attractions/disney.jpg',
  },
  {
    name: keys.SHANGHAI_TOWER,
    description: keys.SHANGHAI_TOWER_DESC,
    img: '/content/attractions/shanghaitower.jpg',
  },
  {
    name: keys.PEARL_TOWER,
    description: keys.PEARL_TOWER_DESC,
    img: '/content/attractions/pearltower.jpg',
  },
  {
    name: keys.NANJING_ROAD,
    description: keys.NANJING_ROAD_DESC,
    img: '/content/attractions/nanjingroad.jpg',
  },
  {
    name: keys.SHANGHAI_MUSEUM,
    description: keys.SHANGHAI_MUSEUM_DESC,
    img: '/content/attractions/shanghaimuseum.jpg',
  },
  {
    name: keys.SHANGAI_OCEAN_AQUARIUM,
    description: keys.SHANGAI_OCEAN_AQUARIUM_DESC,
    img: '/content/attractions/shanghaiaquarium.jpg',
  },
  {
    name: keys.TIANZIFANG,
    description: keys.TIANZIFANG_DESC,
    img: '/content/attractions/tianzifang.jpg',
  },
  {
    name: keys.ZHUJIAJIAO,
    description: keys.ZHUJIAJIAO_DESC,
    img: '/content/attractions/zhujiajiao.jpg',
  },
  {
    name: keys.HAPPY_VALLEY,
    description: keys.HAPPY_VALLEY_DESC,
    img: '/content/attractions/happyvalley.jpg',
  },
  {
    name: keys.SHANGHAI_ZOO,
    description: keys.SHANGHAI_ZOO_DESC,
    img: '/content/attractions/shanghaizoo.jpg',
  },
  {
    name: keys.SHANGHAI_WORLD_FINANCIAL_CENTER,
    description: keys.SHANGHAI_WORLD_FINANCIAL_CENTER_DESC,
    img: '/content/attractions/shanghaidowntown.jpg',
  },
  {
    name: keys.JINGAN_TEMPLE,
    description: keys.JINGAN_TEMPLE_DESC,
    img: '/content/attractions/jingantemple.jpg',
  },
  {
    name: keys.LONGHUA_TEMPLE,
    description: keys.LONGHUA_TEMPLE_DESC,
    img: '/content/attractions/longhuatemple.jpg',
  },
];

const xianAttractions = [
  {
    name: keys.TERRACOTTA_ARMY,
    description: keys.TERRACOTTA_ARMY_DESC,
    img: '/content/attractions/terracottaarmy.jpg',
  },
  {
    name: keys.GIAN_WILD_GOOSE_PAGODA,
    description: keys.GIAN_WILD_GOOSE_PAGODA_DESC,
    img: '/content/attractions/goosepagoda.jpg',
  },
  {
    name: keys.GREAT_MOSQUE,
    description: keys.GREAT_MOSQUE_DESC,
    img: '/content/attractions/xianmosque.jpg',
  },
  {
    name: keys.SHAANXI_HISTORY_MUSEUM,
    description: keys.SHAANXI_HISTORY_MUSEUM_DESC,
    img: '/content/attractions/shaanximuseum.jpg',
  },
  {
    name: keys.BELL_TOWER,
    description: keys.BELL_TOWER_DESC,
    img: '/content/attractions/belltower.jpg',
  },
  {
    name: keys.FORTIFICATIONS_OF_XIAN,
    description: keys.FORTIFICATIONS_OF_XIAN_DESC,
    img: '/content/attractions/xianfortifications.jpg',
  },
  {
    name: keys.XIAN_MUSEUM,
    description: keys.XIAN_MUSEUM_DESC,
    img: '/content/attractions/xianmuseum.jpg',
  },
  {
    name: keys.HUAQING_RELIC,
    description: keys.HUAQING_RELIC_DESC,
    img: '/content/attractions/huaqingrelic.jpg',
  },
  {
    name: keys.TANG_PARADISE,
    description: keys.TANG_PARADISE_DESC,
    img: '/content/attractions/tangparadise.jpg',
  },
];

const Travel = () => {
  return (
    <CardBody className="flex flex-col justify-content-start gap-8">
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.TRAVEL_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.TRAVEL_1} />
        </div>
      </div>
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.BLANK} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.TRAVEL_FROM_INFO} />
        </div>
      </div>
      <hr />
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.HOTEL_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.HOTEL} />
        </div>
      </div>
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.BLANK} />
        </div>
        <div className="flex flex-col gap-4 w-full md:w-2/3">
          <div className="text-md md:text-lg">
            <Button href="http://www.jeuronghotelshanghai.com/">
              <Text textKey={keys.HOTEL_HEADER} />
            </Button>
          </div>
          <div className="flex gap-4 flex-wrap md:flex-nowrap">
            <img src="/content/attractions/jeurong1.jpg" alt="hotel1" className="w-full md:w-1/2" />
            <img src="/content/attractions/jeurong2.jpg" alt="hotel1" className="w-full md:w-1/2" />
          </div>
        </div>
      </div>
      <hr />
      <div className="text-lg md:text-2xl text-center font-medium">
        <Text textKey={keys.THINGS_TO_SEE_SHANGHAI} />
      </div>
      <div className="flex flex-wrap">
        {shanghaiAttractions.map((attraction) => (
          <div className="flex flex-col gap-4 w-full md:w-1/2 p-2">
            <Card className="p-2">
              <img src={attraction.img} alt={attraction.name} className="w-full" />
              <div className="text-lg md:text-2xl text-left font-medium">
                <Text textKey={attraction.name} />
              </div>
              <div className="text-md md:text-lg text-left">
                <Text textKey={attraction.description} />
              </div>
            </Card>
          </div>
        ))}
      </div>
      <hr />
      <div className="text-lg md:text-2xl text-center font-medium">
        <Text textKey={keys.THINGS_TO_SEE_XIAN} />
      </div>
      <div className="flex flex-wrap">
        {xianAttractions.map((attraction) => (
          <div className="flex flex-col gap-4 w-full md:w-1/2 p-2">
            <Card className="p-2">
              <img src={attraction.img} alt={attraction.name} className="w-full" />
              <div className="text-lg md:text-2xl text-left font-medium">
                <Text textKey={attraction.name} />
              </div>
              <div className="text-md md:text-lg text-left">
                <Text textKey={attraction.description} />
              </div>
            </Card>
          </div>
        ))}
      </div>
    </CardBody>
  );
};

export default Travel;

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';

import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';

import { seattle, boston, indiana, seattleThumbnails, bostonThumbnails, indianaThumbnails } from 'utils/photos';
import Text from 'components/Text';
import { keys } from 'utils/Localization';

import CardBody from 'components/base/CardBody';

const allPhotos = [...seattle, ...indiana, ...boston];

const OurStory = () => {
  const [index, setIndex] = React.useState(-1);

  const [seattle1, seattle2] = useMemo(() => {
    const { length } = seattleThumbnails;
    const half = Math.floor(length / 2);
    return [seattleThumbnails.slice(0, half), seattle.slice(half, length)];
  }, []);

  const [indiana1, indiana2] = useMemo(() => {
    const { length } = indianaThumbnails;
    const half = Math.floor(length / 2);
    return [indianaThumbnails.slice(0, half), indiana.slice(half, length)];
  }, []);

  const [boston1, boston2] = useMemo(() => {
    const { length } = bostonThumbnails;
    const half = Math.floor(length / 2);
    return [bostonThumbnails.slice(0, half), boston.slice(half, length)];
  }, []);

  return (
    <CardBody className="flex flex-col justify-content-start gap-2">
      <PhotoAlbum layout="rows" photos={seattle1} targetRowHeight={150} onClick={(event) => setIndex(event.index)} />
      <div className="flex flex-wrap justify-between mt-4 md:flex-row-reverse">
        <div className="flex flex-col justify-between items-center space-y-2 w-full md:w-1/2">
          <div className="flex flex-col justify-end items-center space-y-1 md:space-y-4 flex-grow">
            <div className="text-xl md:text-4xl text-center font-serif">
              <Text textKey={keys.HOW_WE_MET} />
            </div>
            <div className="text-lg md:text-2xl text-center font-serif">
              <Text textKey={keys.HOW_WE_MET_SUBTITLE} />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pb-2 px-10 flex-grow">
            <div className="text-sm md:text-lg text-center font-thin">
              <Text textKey={keys.HOW_WE_MET_BLURB} />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-100 flex flex-col justify-end">
          <img src="/content/howwemet.png" alt="banner" className="object-contain" />
        </div>
      </div>
      <PhotoAlbum
        layout="rows"
        photos={seattle2}
        targetRowHeight={150}
        onClick={(event) => setIndex(event.index + seattle1.length)}
      />
      <PhotoAlbum
        layout="rows"
        photos={indiana1}
        targetRowHeight={150}
        onClick={(event) => setIndex(event.index + seattle1.length + seattle2.length)}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className="flex flex-col justify-between items-center space-y-2 w-full md:w-1/2">
          <div className="flex flex-col justify-end items-center space-y-1 md:space-y-4 flex-grow">
            <div className="text-xl md:text-4xl text-center font-serif">
              <Text textKey={keys.OUR_LIFE_TOGETHER} />
            </div>
            <div className="text-lg md:text-2xl text-center font-serif">
              <Text textKey={keys.OUR_LIFE_TOGETHER_SUBTITLE} />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pb-2 px-10 flex-grow">
            <div className="text-sm md:text-lg text-center font-thin">
              <Text textKey={keys.OUR_LIFE_TOGETHER_BLURB} />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-100 flex flex-col justify-end">
          <img src="/content/ourlifetogether.png" alt="proposal" className="object-contain" />
        </div>
      </div>
      <PhotoAlbum
        layout="rows"
        photos={indiana2}
        targetRowHeight={150}
        onClick={(event) => setIndex(event.index + seattle1.length + seattle2.length + indiana1.length)}
      />
      <PhotoAlbum
        layout="rows"
        photos={boston1}
        targetRowHeight={150}
        onClick={(event) =>
          setIndex(event.index + seattle1.length + seattle2.length + indiana1.length + indiana2.length)
        }
      />
      <div className="flex flex-wrap justify-between mt-4 md:flex-row-reverse">
        <div className="flex flex-col justify-between items-center space-y-2 w-full md:w-1/2">
          <div className="flex flex-col justify-end items-center space-y-1 md:space-y-4 flex-grow">
            <div className="text-xl md:text-4xl text-center font-serif">
              <Text textKey={keys.THE_PROPOSAL} />
            </div>
            <div className="text-lg md:text-2xl text-center font-serif">
              <Text textKey={keys.THE_PROPOSAL_SUBTITLE} />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pb-2 px-10 flex-grow">
            <div className="text-sm md:text-lg text-center font-thin">
              <Text textKey={keys.THE_PROPOSAL_BLURB} />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-100 flex flex-col justify-end">
          <img src="/content/proposal.png" alt="proposal" className="object-contain" />
        </div>
      </div>
      <PhotoAlbum
        layout="rows"
        photos={boston2}
        targetRowHeight={150}
        onClick={(event) =>
          setIndex(event.index + seattle1.length + seattle2.length + indiana1.length + indiana2.length + boston1.length)
        }
      />
      <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={allPhotos} />
    </CardBody>
  );
};

export default OurStory;

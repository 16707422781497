/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from 'components/Text';

const Tab = ({ name, onClick, active }) => {
  if (active) {
    return (
      <div className="flex-grow flex justify-center items-center py-2">
        <span
          className={classNames({
            'font-semibold text-md md:text-lg text-neutral-900': true,
          })}
        >
          <Text textKey={name} />
        </span>
      </div>
    );
  }

  return (
    <div className="flex-grow flex justify-center items-center py-2">
      <a
        onClick={onClick}
        className={classNames({
          'font-semibold  text-md md:text-lg transition duration-500 hover:text-neutral-900': true,
          'text-neutral-400 cursor-pointer': true,
        })}
      >
        <Text textKey={name} />
      </a>
    </div>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Tab;

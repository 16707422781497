/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Text from 'components/Text';
import CardBody from 'components/base/CardBody';
import Button from 'components/base/Button';

import { keys } from 'utils/Localization';

const Details = () => {
  return (
    <CardBody className="flex flex-col justify-content-start gap-8">
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.WHERE_WHEN_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.WHERE_WHEN} />
        </div>
      </div>
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.REGISTRY_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.REGISTRY} />
        </div>
      </div>
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.BLANK} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.PAYPAL_BLURB} />
          <br />
          <Button href="https://www.paypal.com/donate/?business=2RN4835NCHN48&no_recurring=1&item_name=Gwen+and+Zoe%27s+wedding+fund.&currency_code=USD">
            <Text textKey={keys.PAYPAL_LINK} />
          </Button>
        </div>
      </div>
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.WEDDING_PARTY_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.WEDDING_PARTY} />
        </div>
      </div>
    </CardBody>
  );
};

export default Details;

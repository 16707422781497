import shuffle from 'utils/Collections';

const convert = (files, dir) => {
  const photos = files.map((photo, index) => {
    const { dim, src } = photo;
    return {
      src: `/content/gallery/${dir}/${src}`,
      key: `${index}`,
      width: dim[0],
      height: dim[1],
    };
  });

  const thumbnails = files.map((photo, index) => {
    const { dim, src } = photo;
    return {
      src: `/content/gallery/${dir}/thumbnail/${src}`,
      key: `${index}`,
      width: dim[0] / 5,
      height: dim[1] / 5,
    };
  });

  return [photos, thumbnails];
};

const [seattle, seattleThumbnails] = convert(
  shuffle([
    { src: '1.jpg', dim: [720, 960] },
    { src: '10.jpg', dim: [1280, 960] },
    { src: '11.jpg', dim: [962, 1280] },
    { src: '13.jpg', dim: [1179, 1572] },
    { src: '12.jpg', dim: [960, 1280] },
    { src: '14.jpg', dim: [720, 960] },
    { src: '15.jpg', dim: [768, 1024] },
    { src: '16.jpg', dim: [1179, 884] },
    { src: '17.jpg', dim: [960, 576] },
    { src: '18.jpg', dim: [1280, 960] },
    { src: '19.jpg', dim: [1179, 858] },
    { src: '2.jpg', dim: [1280, 720] },
    { src: '20.jpg', dim: [1179, 914] },
    { src: '21.jpg', dim: [1179, 965] },
    { src: '22.jpg', dim: [750, 1334] },
    { src: '23.jpg', dim: [1179, 1572] },
    { src: '24.jpg', dim: [1179, 884] },
    { src: '25.jpg', dim: [1179, 884] },
    { src: '26.jpg', dim: [1179, 884] },
    { src: '27.jpg', dim: [1179, 886] },
    { src: '28.jpg', dim: [700, 1050] },
    { src: '29.jpg', dim: [1080, 648] },
    { src: '3.jpg', dim: [1024, 768] },
    { src: '30.jpg', dim: [960, 960] },
    { src: '4.jpg', dim: [704, 960] },
    { src: '31.jpg', dim: [1009, 1723] },
    { src: '5.jpg', dim: [720, 960] },
    { src: '6.jpg', dim: [960, 1280] },
    { src: '7.jpg', dim: [1536, 2048] },
    { src: '8.jpg', dim: [1008, 756] },
    { src: '9.jpg', dim: [1280, 960] },
  ]),
  'seattle',
);

const [indiana, indianaThumbnails] = convert(
  shuffle([
    { src: '1.jpg', dim: [1280, 960] },
    { src: '10.jpg', dim: [1440, 1080] },
    { src: '13.jpg', dim: [480, 640] },
    { src: '11.jpg', dim: [360, 480] },
    { src: '12.jpg', dim: [2048, 1539] },
    { src: '15.jpg', dim: [2048, 1536] },
    { src: '14.jpg', dim: [2048, 1536] },
    { src: '16.jpg', dim: [1520, 2048] },
    { src: '2.jpg', dim: [2048, 1536] },
    { src: '22.jpg', dim: [1536, 2048] },
    { src: '23.jpg', dim: [1536, 2048] },
    { src: '25.jpg', dim: [1536, 2048] },
    { src: '24.jpg', dim: [1536, 2048] },
    { src: '27.jpg', dim: [828, 565] },
    { src: '26.jpg', dim: [1530, 2048] },
    { src: '28.jpg', dim: [2048, 1536] },
    { src: '29.jpg', dim: [1179, 884] },
    { src: '31.jpg', dim: [1179, 1569] },
    { src: '3.jpg', dim: [2048, 1536] },
    { src: '30.jpg', dim: [1179, 663] },
    { src: '32.jpg', dim: [1179, 884] },
    { src: '34.jpg', dim: [1179, 786] },
    { src: '33.jpg', dim: [1179, 786] },
    { src: '35.jpg', dim: [1179, 786] },
    { src: '36.jpg', dim: [1179, 884] },
    { src: '37.jpg', dim: [1179, 802] },
    { src: '38.jpg', dim: [960, 576] },
    { src: '39.jpg', dim: [1080, 1080] },
    { src: '4.jpg', dim: [1536, 2048] },
    { src: '40.jpg', dim: [576, 960] },
    { src: '5.jpg', dim: [1516, 2048] },
    { src: '8.jpg', dim: [1440, 1080] },
    { src: '6.jpg', dim: [2048, 1536] },
    { src: '9.jpg', dim: [1536, 2048] },
    { src: '7.jpg', dim: [1536, 2048] },
  ]),
  'indiana',
);

const [boston, bostonThumbnails] = convert(
  shuffle([
    { src: '1.jpg', dim: [2048, 1536] },
    { src: '10.jpg', dim: [2048, 1365] },
    { src: '11.jpg', dim: [2028, 2048] },
    { src: '12.jpg', dim: [2048, 1311] },
    { src: '13.jpg', dim: [2048, 2048] },
    { src: '14.jpg', dim: [2048, 1153] },
    { src: '15.jpg', dim: [2048, 1173] },
    { src: '16.jpg', dim: [1536, 2048] },
    { src: '17.jpg', dim: [1170, 871] },
    { src: '18.jpg', dim: [1706, 1280] },
    { src: '19.jpg', dim: [2048, 1536] },
    { src: '20.jpg', dim: [1536, 2048] },
    { src: '21.jpg', dim: [2048, 1536] },
    { src: '22.jpg', dim: [1536, 2048] },
    { src: '23.jpg', dim: [828, 622] },
    { src: '24.jpg', dim: [1170, 1560] },
    { src: '25.jpg', dim: [2048, 1536] },
    { src: '29.jpg', dim: [2048, 1536] },
    { src: '3.jpg', dim: [2048, 1152] },
    { src: '30.jpg', dim: [1179, 1572] },
    { src: '31.jpg', dim: [1179, 1572] },
    { src: '32.jpg', dim: [1179, 884] },
    { src: '33.jpg', dim: [1179, 884] },
    { src: '34.jpg', dim: [1179, 884] },
    { src: '35.jpg', dim: [1179, 884] },
    { src: '37.jpg', dim: [1179, 1572] },
    { src: '4.jpg', dim: [1536, 2048] },
    { src: '36.jpg', dim: [1179, 1572] },
    { src: '41.jpg', dim: [1536, 2048] },
    { src: '42.jpg', dim: [1536, 2048] },
    { src: '43.jpg', dim: [2048, 1536] },
    { src: '44.jpg', dim: [1536, 2048] },
    { src: '45.jpg', dim: [1080, 1920] },
    { src: '5.jpg', dim: [1536, 2048] },
    { src: '6.jpg', dim: [1170, 1560] },
    { src: '7.jpg', dim: [2048, 1536] },
    { src: '39.jpg', dim: [1179, 1179] },
    { src: '40.jpg', dim: [1179, 884] },
    { src: '38.jpg', dim: [1179, 1572] },
  ]),
  'boston',
);

export { seattle, indiana, boston, seattleThumbnails, indianaThumbnails, bostonThumbnails };

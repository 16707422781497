/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useLanguage from 'hooks/useLanguage';
import Text from 'components/Text';
import { keys } from 'utils/Localization';

const flags = {
  en: 'flag_en',
  cn: 'flag_cn',
  nl: 'flag_nl',
};

const MainLayout = ({ children }) => {
  const { language, setLanguage } = useLanguage();

  const updateLanguage = useCallback(
    (e) => {
      setLanguage(e);

      // refresh
      window.location.reload();
    },
    [setLanguage],
  );

  return (
    <div className="bg-neutral-100 min-h-screen">
      <div className="container mx-auto p-2">
        <div className="flex justify-end space-x-2">
          {Object.keys(flags).map((flag) => (
            <img
              key={flag}
              src={`/content/${flags[flag]}.png`}
              alt={flag}
              className={classNames({
                'w-8 h-8 cursor-pointer select-none': true,
                'opacity-30': language !== flag,
                'transition duration-250 ease-in-out transform hover:-translate-y-1 hover:scale-110': true,
              })}
              onClick={() => updateLanguage(flag)}
              style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
            />
          ))}
        </div>
        <div className="flex justify-between mt-4">
          <div className="flex flex-col justify-between items-center space-y-2 flex-grow">
            <div className="flex flex-col justify-center items-center space-y-1 md:space-y-4 flex-grow">
              <div className="text-xl md:text-4xl text-center font-serif">
                <Text textKey={keys.GWEN_DEKKER} />
              </div>
              <div className="text-lg md:text-2xl text-center font-serif">
                <Text textKey={keys.AND} />
              </div>
              <div className="text-xl md:text-4xl text-center font-serif">
                <Text textKey={keys.ZOE_WU} />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center pb-2">
              <div className="text-sm md:text-lg text-center font-serif italic font-thin">
                <Text textKey={keys.WHEN} />
              </div>
              <div className="text-sm md:text-lg text-center font-serif italic font-thin">
                <Text textKey={keys.WHERE} />
              </div>
            </div>
          </div>
          <div className="w-1/2 h-100 flex flex-col justify-end">
            <img src="/content/banner.png" alt="banner" className="object-contain" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;

import React from 'react';

const Spinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full border-t-2 border-b-2 border-gray-900 h-5 w-5" />
    </div>
  );
};

export default Spinner;

/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import MessagesContext from 'contexts/MessagesContext';
import MainLayout from 'layouts/MainLayout';

const RenderToRoot = (Element) => {
  const reactProps = typeof window !== 'undefined' ? window.reactProps : {};

  const element = (
    <ErrorBoundary className="mt-3">
      <MessagesContext.Provider value={reactProps.messages}>
        <MainLayout activeRef={reactProps.loginCallback}>
          <Element {...reactProps} />
        </MainLayout>
      </MessagesContext.Provider>
    </ErrorBoundary>
  );
  if (typeof document !== 'undefined') {
    const wrapper = document.getElementById('react-root');
    if (wrapper) {
      if (wrapper.children.length === 0) {
        ReactDOM.render(element, wrapper);
      } else {
        ReactDOM.hydrate(element, wrapper);
      }
    }
  }

  return Element;
};

export default RenderToRoot;

import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ children }) => {
  return <span className="text-lg font-semibold">{children}</span>;
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Label;

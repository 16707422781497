/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';

import Card from 'components/base/Card';
import Text from 'components/Text';
import { keys } from 'utils/Localization';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, error: '', stack: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message, stack: error.stack };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, error, stack } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="mt-3">
          <h1 className="text-center">
            <Text textKey={keys.ERROR} />
          </h1>
          <p className="text-center">
            <Text textKey={keys.TRY_RELOAD} />
          </p>
          <br />
          <Card>
            <p>
              <code>{error}</code>
            </p>
            <p>
              <code>
                {stack.split('\n').map((text) => (
                  <Fragment key={text}>
                    {text}
                    <br />
                  </Fragment>
                ))}
              </code>
            </p>
          </Card>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

const keys = {
  LANDING: 'landing',
  ERROR: 'error',
  TRY_RELOAD: 'reload',
  WELCOME: 'welcome',
  WELCOME_2: 'welcome2',
  OUR_STORY: 'ourstory',
  WEDDING_DETAILS: 'weddingdetails',
  TRAVEL: 'travel',
  RSVP: 'rsvp',
  GWEN: 'gwen',
  ZOE: 'zoe',
  GWEN_DEKKER: 'gwendekker',
  ZOE_WU: 'zoewu',
  AND: 'and',
  WHEN: 'when',
  WHERE: 'where',
  HOW_WE_MET: 'howwemet',
  HOW_WE_MET_SUBTITLE: 'howwemettitle',
  HOW_WE_MET_BLURB: 'howwemetblurb',
  THE_PROPOSAL: 'theproposal',
  THE_PROPOSAL_SUBTITLE: 'theproposalsubtitle',
  THE_PROPOSAL_BLURB: 'theproposalblurb',
  OUR_LIFE_TOGETHER: 'ourlifetogether',
  OUR_LIFE_TOGETHER_SUBTITLE: 'ourlifetogethersubtitle',
  OUR_LIFE_TOGETHER_BLURB: 'ourlifetogetherblurb',
  TRAVEL_HEADER: 'travelheader',
  TRAVEL_1: 'travel1',
  TRAVEL_FROM_INFO: 'travelfromus',
  BLANK: 'blank',
  HOTEL_HEADER: 'hotelheader',
  HOTEL: 'hotel',
  THINGS_TO_SEE_SHANGHAI: 'thingstoseeshanghai',
  THE_BUND: 'thebund',
  THE_BUND_DESC: 'thebunddesc',
  YUYUAN_GARDEN: 'yuyuangarden',
  YUYUAN_GARDEN_DESC: 'yuyuangardendesc',
  JADE_BUDDHA_TEMPLE: 'jadebuddhatemple',
  JADE_BUDDHA_TEMPLE_DESC: 'jadebuddhatempledesc',
  DISNEYLAND: 'disneyland',
  DISNEYLAND_DESC: 'disneylanddesc',
  SHANGHAI_TOWER: 'shanghaitower',
  SHANGHAI_TOWER_DESC: 'shanghaitowerdesc',
  PEARL_TOWER: 'pearltower',
  PEARL_TOWER_DESC: 'pearltowerdesc',
  NANJING_ROAD: 'nanjingroad',
  NANJING_ROAD_DESC: 'nanjingroaddesc',
  SHANGHAI_MUSEUM: 'shanghaimuseum',
  SHANGHAI_MUSEUM_DESC: 'shanghaimuseumdesc',
  SHANGAI_OCEAN_AQUARIUM: 'shanghaioceanaquarium',
  SHANGAI_OCEAN_AQUARIUM_DESC: 'shanghaioceanaquariumdesc',
  TIANZIFANG: 'tianzifang',
  TIANZIFANG_DESC: 'tianzifangdesc',
  ZHUJIAJIAO: 'zhujiajiao',
  ZHUJIAJIAO_DESC: 'zhujiajiaodesc',
  HAPPY_VALLEY: 'happyvalley',
  HAPPY_VALLEY_DESC: 'happyvalleydesc',
  SHANGHAI_ZOO: 'shanghaizoo',
  SHANGHAI_ZOO_DESC: 'shanghaizoodesc',
  SHANGHAI_WORLD_FINANCIAL_CENTER: 'shanghaiworldfinancialcenter',
  SHANGHAI_WORLD_FINANCIAL_CENTER_DESC: 'shanghaiworldfinancialcenterdesc',
  JINGAN_TEMPLE: 'jingantemple',
  JINGAN_TEMPLE_DESC: 'jingantempledesc',
  LONGHUA_TEMPLE: 'longhuatemple',
  LONGHUA_TEMPLE_DESC: 'longhuatempledesc',
  WHERE_WHEN_HEADER: 'wherewhenheader',
  WHERE_WHEN: 'wherewhen',
  REGISTRY_HEADER: 'registryheader',
  REGISTRY: 'registry',
  WEDDING_PARTY_HEADER: 'weddingpartyheader',
  WEDDING_PARTY: 'weddingparty',
  RSVP_HEADER: 'rsvpheader',
  RSVP_CONTENT: 'rsvpcontent',
  NAME: 'name',
  EMAIL: 'email',
  GUESTS: 'guests',
  DATES: 'dates',
  COMMENTS: 'comments',
  SUBMIT: 'submit',
  THANK_YOU: 'thankyou',
  RETURN_HOME: 'returnhome',
  SUCCESS_HEADER: 'successheader',
  SUCCESS_CONTENT: 'successcontent',
  TERRACOTTA_ARMY: 'terracottaarmy',
  TERRACOTTA_ARMY_DESC: 'terracottaarmydesc',
  GIAN_WILD_GOOSE_PAGODA: 'gianwildgoosepagoda',
  GIAN_WILD_GOOSE_PAGODA_DESC: 'gianwildgoosepagodadesc',
  GREAT_MOSQUE: 'greatmosque',
  GREAT_MOSQUE_DESC: 'greatmosquedesc',
  FORTIFICATIONS_OF_XIAN: 'fortificationsofxian',
  FORTIFICATIONS_OF_XIAN_DESC: 'fortificationsofxiandesc',
  XIAN_MUSEUM: 'xianmuseum',
  XIAN_MUSEUM_DESC: 'xianmuseumdesc',
  HUAQING_RELIC: 'huaqingrelic',
  HUAQING_RELIC_DESC: 'huaqingrelicdesc',
  TANG_PARADISE: 'tangparadise',
  TANG_PARADISE_DESC: 'tangparadisedesc',
  THINGS_TO_SEE_XIAN: 'thingstoseexian',
  SHAANXI_HISTORY_MUSEUM: 'shanxihistorymuseum',
  SHAANXI_HISTORY_MUSEUM_DESC: 'shanxihistorymuseumdesc',
  BELL_TOWER: 'belltower',
  BELL_TOWER_DESC: 'belltowerdesc',
  PAYPAL_BLURB: 'paypalblurb',
  PAYPAL_LINK: 'paypallink',
};

const en = {
  [keys.LANDING]: 'Landing',
  [keys.ERROR]: 'Something went wrong.',
  [keys.TRY_RELOAD]: 'You may want to try reloading the page.',
  [keys.WELCOME]: 'Welcome to our Wedding',
  [keys.WELCOME_2]: 'We are so happy you are here!',
  [keys.OUR_STORY]: 'Our Story',
  [keys.WEDDING_DETAILS]: 'Wedding Details',
  [keys.TRAVEL]: 'Travel',
  [keys.RSVP]: 'RSVP',
  [keys.GWEN]: 'Gwen',
  [keys.ZOE]: 'Zoe',
  [keys.GWEN_DEKKER]: 'Gwen Dekker',
  [keys.ZOE_WU]: 'Zoe Wu',
  [keys.AND]: '~ and ~',
  [keys.WHEN]: 'September 15, 2024',
  [keys.WHERE]: 'Shanghai, China',
  [keys.HOW_WE_MET]: 'How We Met',
  [keys.HOW_WE_MET_SUBTITLE]: 'College kids in love',
  [keys.HOW_WE_MET_BLURB]: `We met each other during Zoe's first year studying abroad in Seattle. We were both young college students trying to find our way in the world. We started dating dating in 2015, right before Zoe returned to China for summer vacation after her first year of school in the US. After a lonely three months apart, Zoe returned for her second year of school and we have been unseparable ever since.`,
  [keys.THE_PROPOSAL]: 'The Proposal',
  [keys.THE_PROPOSAL_SUBTITLE]: 'A romantic evening in Seaport',
  [keys.THE_PROPOSAL_BLURB]:
    'Gwen had arranged a romantic dinner at one of our favorite restaraunts in Seaport, Boston. Gwen had prepared a handmade sign that said `Zoe, will you marry me?`, and had the waiter bring it out with dessert. Zoe was so flustered she could hardly get the words out, but she managed to say yes! We are so excited to spend the rest of our lives together.',
  [keys.OUR_LIFE_TOGETHER]: 'Our Life Together',
  [keys.OUR_LIFE_TOGETHER_SUBTITLE]: 'A new chapter',
  [keys.OUR_LIFE_TOGETHER_BLURB]: `Since Zoe and Gwen first met in 2015, they have accomplished so much together. They supported each other through college, and moved to Boston for Gwen's first job. In Boston, they both attended Northeastern University for graduate school. They bought a lovely home together in Quincy, MA, where they live with their two beautiful dogs, Yuki and Lulu.`,
  [keys.TRAVEL_HEADER]: 'Traveling Internationally to China?',
  [keys.TRAVEL_1]: `We are so excited to see you in Shanghai! We understand that traveling internationally can be difficult, so we have put together some information to help you plan your trip. Since there is so much to do and see while you're, we recommend you visit for a few extra days. If you arrive before the wedding, we will be in Shanghai and would love to explore some of the amazing sights with you! If you're in town following the wedding, we will be taking a train to Xi'an, where there is no shortage of tourist attractions. Please let us know ahead of time what your plans are so we can coordinate with you!`,
  [keys.BLANK]: '',
  [keys.TRAVEL_FROM_INFO]: `If you are traveling from the US, you will need to apply for a visa. You can apply for a tourist visa at your local Chinese consulate. You will need to provide a copy of your passport, a passport photo, and a completed visa application. You will also need to provide a letter of invitation from us. There is likely a private visa service in your area that can help you with the process, which we recommend utilizing if this is your first time traveling to China.`,
  [keys.HOTEL_HEADER]: 'Hotel Information',
  [keys.HOTEL]: `As foreigners in China, there are regulations around what hotel you're able to stay at. We recommend staying at Jeurong Hotel. This hotel is close to where we are staying, it's a five minute walk from metro line one. We will arrange a shuttle to the wedding venue.  If you would like to stay at a different hotel, please let us know and we will help you find one that meets the requirements.`,
  [keys.THINGS_TO_SEE_SHANGHAI]: 'Things to see while you are in Shanghai',
  [keys.THE_BUND]: 'The Bund',
  [keys.THE_BUND_DESC]: `The Bund is a waterfront area and a protected historical district in central Shanghai.the Bund has graced the city's waterfront for nearly 200 years. Its 19th-century architecture highlights its roots in the era of the Opium Wars, which was followed by the arrival of Western banks and trading houses in Shanghai.`,
  [keys.YUYUAN_GARDEN]: 'Yu Yuan Garden',
  [keys.YUYUAN_GARDEN_DESC]: `Yu Garden or Yuyuan Garden is an extensive Chinese garden located beside the City God Temple in the northeast of the Old City of Shanghai at Huangpu District, Shanghai. It abuts the Yuyuan Tourist Mart, the Huxinting Teahouse and the Yu Garden Bazaar.`,
  [keys.JADE_BUDDHA_TEMPLE]: 'Jade Buddha Temple',
  [keys.JADE_BUDDHA_TEMPLE_DESC]: `The Jade Buddha Temple is a Buddhist temple in Shanghai. As with many modern Chinese Buddhist temples, the current temple draws from both the Pure Land and Chan traditions of Mahayana Buddhism. It was founded in 1882 with two jade Buddha statues imported to Shanghai from Burma by sea.`,
  [keys.DISNEYLAND]: 'Disneyland',
  [keys.DISNEYLAND_DESC]: `Shanghai Disneyland Park is a theme park located in Pudong, Shanghai, China, that is part of the Shanghai Disney Resort. The park is operated by Disney Parks, Experiences and Products and Shanghai Shendi Group, through a joint venture between The Walt Disney Company and Shendi.`,
  [keys.SHANGHAI_TOWER]: 'Shanghai Tower',
  [keys.SHANGHAI_TOWER_DESC]: `Shanghai Tower is a 128-story, 632-meter-tall megatall skyscraper in Lujiazui, Pudong, Shanghai. It is the tallest building in China and the world's third-tallest building by height to architectural top. It is the tallest and largest LEED Platinum certified building in the world since 2015.`,
  [keys.PEARL_TOWER]: 'Pearl Tower',
  [keys.PEARL_TOWER_DESC]: `The Oriental Pearl Radio & Television Tower is a TV tower in Shanghai. Its location at the tip of Lujiazui in the Pudong district by the side of Huangpu River, opposite The Bund, makes it a distinct landmark in the area.`,
  [keys.NANJING_ROAD]: 'Nanjing Road',
  [keys.NANJING_ROAD_DESC]: `Nanjing Road Nanjing Road is one of the world's busiest shopping streets. Nanjing Road is a road in Shanghai, the eastern part of which is the main shopping district of Shanghai.`,
  [keys.SHANGHAI_MUSEUM]: 'Shanghai Museum',
  [keys.SHANGHAI_MUSEUM_DESC]: `The Shanghai Museum is a municipal public museum of ancient Chinese art, situated on the People's Square in the Huangpu District of Shanghai, China. It is funded by the Shanghai Municipal Culture and Tourism Bureau. Rebuilt at its current location in 1996, it is famous for its large collection of rare cultural pieces`,
  [keys.SHANGAI_OCEAN_AQUARIUM]: 'Shanghai Ocean Aquarium',
  [keys.SHANGAI_OCEAN_AQUARIUM_DESC]: `the aquarium includes a 168-meter tunnel that takes visitors through a coastal reef, open ocean, a kelp cave, shark cove, and a coral reef, and is one of the longest such tunnels in the world.`,
  [keys.TIANZIFANG]: 'Tianzifang',
  [keys.TIANZIFANG_DESC]: `Tianzifang or Tianzi Fang is a touristic arts and crafts enclave that has developed from a renovated traditional residential area in the French Concession area of Shanghai. It is now home to boutique shops, bars and restaurants.`,
  [keys.ZHUJIAJIAO]: 'Zhujiajiao',
  [keys.ZHUJIAJIAO_DESC]: `Zhujiajiao is a water town on the outskirts of Shanghai, and was established about 1,700 years ago. Archaeological findings dating back 5,000 years have also been found.`,
  [keys.HAPPY_VALLEY]: 'Happy Valley',
  [keys.HAPPY_VALLEY_DESC]: `Happy Valley Shanghai is an amusement park in Songjiang District, Shanghai, China. The park is located in Linhu Town. Opened on 16 August 2009, it is the fourth installation of the Happy Valley theme park chain. The park spans an area of 863,500 square meters.`,
  [keys.SHANGHAI_ZOO]: 'Shanghai Zoo',
  [keys.SHANGHAI_ZOO_DESC]: `The zoo houses and exhibits more than 6,000 animals, among which are 600 Chinese animals that include giant pandas, golden snub-nosed monkeys, lions, South China tigers, hoopoe, black bulbuls, great hornbills and Bactrian camels.`,
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER]: 'Shanghai World Financial Center',
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER_DESC]: `The Shanghai World Financial Center is a supertall skyscraper located in the Pudong district of Shanghai. The 94th floor has a observation bridge with glass floors.`,
  [keys.JINGAN_TEMPLE]: 'Jingan Temple',
  [keys.JINGAN_TEMPLE_DESC]: `Jing'an Temple is an esoteric Tangmi Buddhist temple on the West Nanjing Road in Shanghai.`,
  [keys.LONGHUA_TEMPLE]: 'Longhua Temple',
  [keys.LONGHUA_TEMPLE_DESC]: `The Longhua Temple is a Buddhist temple dedicated to the Maitreya Buddha in Shanghai. Although most of the present day buildings date from later reconstructions, the temple preserves the architectural design of a Song dynasty monastery of the Chan School`,
  [keys.WHERE_WHEN_HEADER]: 'Where and When',
  [keys.WHERE_WHEN]: `The wedding will be held at the No. 801, Jumen Road, Wuliqiao Street, Huangpu District, Shanghai. The ceremony will be on September 15, 2024. We will be providing transportation to and from the hotel.`,
  [keys.REGISTRY_HEADER]: 'Registry',
  [keys.REGISTRY]: `Your presence at our wedding is the greatest gift of all. However, if you would like to give us a gift, we will be following the Chinese tradition of giving red envelopes. You can give us a red envelope at the wedding. Please refrain from giving us any physical items, as we will be traveling back to the US after the wedding. Alternatively, you can make a contribution to our wedding fund using the PayPal link below.`,
  [keys.WEDDING_PARTY_HEADER]: 'Wedding Party',
  [keys.WEDDING_PARTY]: `We are so grateful to have such amazing friends and family to support us on our wedding day. We are still working out the details of the wedding party, but we will update this section once we have more information. If you are interested in being a part of the wedding party, please let us know!`,
  [keys.RSVP_HEADER]: 'Can you make it?',
  [keys.RSVP_CONTENT]: `It would truly mean the world to us if you could make it to our wedding. Please let us know if you will be able to attend by filling out the form below. If you have any questions, please don't hesitate to reach out to us.\n\nPlease only RSVP if you are sure you will be able to attend, as we will have limited space at the venue. If you are unable to attend, we will miss you and hope to see you soon!`,
  [keys.NAME]: 'Your Full Name',
  [keys.EMAIL]: 'Your Email',
  [keys.GUESTS]: 'Number of Guests',
  [keys.DATES]: 'How long do you plan to stay in China?',
  [keys.COMMENTS]: 'Comments, or anything else we should know',
  [keys.SUBMIT]: 'Submit',
  [keys.SUCCESS_HEADER]: `We've successfully received your RSVP!`,
  [keys.SUCCESS_CONTENT]: `Thank you so much for letting us know if you can make it. We will be in touch with you as we get closer to the wedding. If you have any questions, please don't hesitate to reach out to us.`,
  [keys.THANK_YOU]: `Thank you so much. We can't wait to see you!`,
  [keys.RETURN_HOME]: 'Return Home',
  [keys.TERRACOTTA_ARMY]: 'Terracotta Army',
  [keys.TERRACOTTA_ARMY_DESC]: `A collection of terracotta sculptures depicting the armies of Qin Shi Huang, the first emperor of China. It is a form of funerary art buried with the emperor in 210–209 BCE with the purpose of protecting him in his afterlife.`,
  [keys.THINGS_TO_SEE_XIAN]: `Things to see while you are in Xi'an`,
  [keys.GIAN_WILD_GOOSE_PAGODA]: 'Giant Wild Goose Pagoda',
  [keys.GIAN_WILD_GOOSE_PAGODA_DESC]: `Giant Wild Goose Pagoda or Big Wild Goose Pagoda, is a monumental Buddhist pagoda located in southern Xi'an, Shaanxi, China. It was built in c. 648 – c. 649 during the Tang dynasty and originally had five stories.`,
  [keys.GREAT_MOSQUE]: `Great Mosque of Xi'an`,
  [keys.GREAT_MOSQUE_DESC]: `The Great Mosque of Xi'an is one of the largest premodern mosques in China. Although the mosque was first built in the year 742 AD during the Tang dynasty, its current form was largely constructed in 1384 AD during Emperor Hongwu's reign of the Ming dynasty, as recorded by the Records of Xi'an Municipality.`,
  [keys.SHAANXI_HISTORY_MUSEUM]: `Shaanxi History Museum`,
  [keys.SHAANXI_HISTORY_MUSEUM_DESC]: `One of the first huge state museums with modern facilities in China and one of the largest.`,
  [keys.FORTIFICATIONS_OF_XIAN]: `Fortifications of Xi'an`,
  [keys.FORTIFICATIONS_OF_XIAN_DESC]: `known as Xi'an City Wall, in Xi'an, represent one of the oldest, largest and best preserved Chinese city walls. It was built under the rule of the Hongwu Emperor Zhu Yuanzhang as a military defense system.`,
  [keys.BELL_TOWER]: `Bell Tower of Xi'an`,
  [keys.BELL_TOWER_DESC]: `Built in 1384 during the early Ming Dynasty, is a symbol of the city of Xi'an and one of the grandest of its kind in China. The Bell Tower also contains several large bronze-cast bells from the Tang Dynasty. The tower base is square and it covers an area of 1,377 m².`,
  [keys.XIAN_MUSEUM]: `Xi'an Museum`,
  [keys.XIAN_MUSEUM_DESC]: `The museum houses over 130,000 items, including murals, paintings, pottery, coins, as well as bronze, gold, and silver objects. It was opened in May 2007, and is housed in a former Confucian Temple.`,
  [keys.HUAQING_RELIC]: `Huaqing Relic Site`,
  [keys.HUAQING_RELIC_DESC]: `Huaqing Palace or Huaqing Hot Springs is a complex of hot springs located in an area characterized by mild weather and scenic views at the northern foot of Mount Li, one of the three major peaks of the Qin Mountains.`,
  [keys.TANG_PARADISE]: `Tang Paradise`,
  [keys.TANG_PARADISE_DESC]: `Tang Paradise is a large park in the city of Xi'an, the capital of Shaanxi province, Northwest China. The park is at or near the site of an earlier garden Furong Garden complex in the city of Chang'an, the capital of the Tang dynasty.`,
  [keys.PAYPAL_BLURB]: `To make a contribution, please use the secure PayPal link below. Your kindness and generosity are deeply appreciated, and we can't wait to celebrate this joyous occasion with you.`,
  [keys.PAYPAL_LINK]: `Click here to make a contribution`,
};

const cn = {
  [keys.LANDING]: `主页`,
  [keys.ERROR]: `网页出错了`,
  [keys.TRY_RELOAD]: `您可以尝试重新加载页面`,
  [keys.WELCOME]: `欢迎来到我们的婚礼`,
  [keys.WELCOME_2]: `我们很高兴你来了！`,
  [keys.OUR_STORY]: `我们的故事`,
  [keys.WEDDING_DETAILS]: `婚礼详情`,
  [keys.TRAVEL]: `旅行计划`,
  [keys.RSVP]: `预定座位`,
  [keys.GWEN]: `格温`,
  [keys.ZOE]: `钟慧`,
  [keys.GWEN_DEKKER]: `戴克 格温`,
  [keys.ZOE_WU]: `吴钟慧`,
  [keys.AND]: `~ 和 ~`,
  [keys.WHEN]: `2024年9月15日`,
  [keys.WHERE]: `中国上海`,
  [keys.HOW_WE_MET]: `我们认识的过程`,
  [keys.HOW_WE_MET_SUBTITLE]: `学生时代的爱情`,
  [keys.HOW_WE_MET_BLURB]: `我们是钟慧在西雅图留学期间认识的。 当时我们都是年轻的大学生，试图寻找人生的目标与意义。 我们从 2015 年开始约会，从此之后再也没有分开过`,
  [keys.THE_PROPOSAL]: `求婚`,
  [keys.THE_PROPOSAL_SUBTITLE]: `在海边的一个浪漫的夜晚`,
  [keys.THE_PROPOSAL_BLURB]: `格温在波士顿的海边安排了一场浪漫的晚餐，他准备了一块手工制作的木牌，让服务生在上甜点前偷偷拿出来，上面写着“佐伊，愿意嫁给我吗？”钟慧很激动，几乎说不出话来，但她还是说出了“愿意”！`,
  [keys.OUR_LIFE_TOGETHER]: `我们的生活`,
  [keys.OUR_LIFE_TOGETHER_SUBTITLE]: `人生新的篇章`,
  [keys.OUR_LIFE_TOGETHER_BLURB]: `自从钟慧和格温在2015年第一次相遇以来，他们共同取得了许多成就。他们在大学时互相支持，之后一起为了格温的第一份工作搬到了波士顿。在波士顿的时候，他们两人都进入了东北大学攻读研究生课程。他们共同在马萨诸塞州昆西购置了一栋可爱的房屋，与两只可爱的狗狗Yuki和Lulu一起生活。`,
  [keys.TRAVEL_HEADER]: `国际旅程`,
  [keys.TRAVEL_1]: `我们非常期待在上海见到你！我们理解国际旅行可能会有一些困难，因此我们整理了一些信息，以帮助你计划你的行程。由于在上海有很多有趣的景点，我们建议你多留几天。如果你在婚礼之前抵达，我们将在上海，很乐意和你一起探索一些令人惊叹的景点！如果你在婚礼之后还在上海，我们计划乘动车前往西安，那里有很多年代久远的景点。请提前告诉我们你的计划，以便我们能够与你协调！`,
  [keys.TRAVEL_FROM_INFO]: `如果你从美国出发，你将需要申请签证。你可以在当地的中国领事馆申请旅游签证。你需要提供护照的复印件、一张护照照片以及填写完整的签证申请表。此外，你还需要提供我们的邀请函。在你所在地区可能有私人签证服务机构，可以帮助你完成整个申请过程，我们建议如果这是你第一次前往中国，可以考虑利用这些服务。`,
  [keys.BLANK]: ``,
  [keys.HOTEL_HEADER]: `酒店详情`,
  [keys.HOTEL]: `作为在中国的外国人，有关你可以入住哪家酒店有一些规定。我们建议选择上海中庚聚龙酒店， 这家酒店离我们的住所很近，而且离地铁一号线只有五分钟步行路程。我们将安排一辆班车送往婚礼场地。如果你想选择其他酒店，请告诉我们，我们将帮助你找到符合要求的酒店。"`,
  [keys.THINGS_TO_SEE_SHANGHAI]: `我们推荐的上海景点`,
  [keys.THE_BUND]: `外滩`,
  [keys.THE_BUND_DESC]: `外滩是上海市中心的一个滨水区和受保护的历史街区。外滩已经装点着这座城市的滨水区近200年了。它的19世纪建筑凸显了它在鸦片战争时期的起源，紧接着是西方银行和贸易公司抵达上海的时代。`,
  [keys.YUYUAN_GARDEN]: `豫园`,
  [keys.YUYUAN_GARDEN_DESC]: `豫园（Yu Garden）又称愚园，是位于上海老城区东北部黄浦区的城隍庙旁边的一座大型中国古典园林。它毗邻豫园旅游城、湖心亭茶楼和豫园市场。`,
  [keys.JADE_BUDDHA_TEMPLE]: `玉佛寺`,
  [keys.JADE_BUDDHA_TEMPLE_DESC]: `玉佛寺是上海的一座佛教寺庙。与许多现代中国佛教寺庙一样，该寺庙融合了大乘佛教的净土和禅宗传统。它建于1882年，当时通过海运从缅甸引入了两尊玉佛雕像到上海。`,
  [keys.DISNEYLAND]: `迪士尼乐园`,
  [keys.DISNEYLAND_DESC]: `上海迪士尼乐园是位于中国上海浦东的主题公园，是上海迪士尼度假区的一部分。该公园由迪士尼主题公园、体验和产品以及上海申迪集团运营，通过华特迪士尼公司和申迪之间的合资企业。`,
  [keys.SHANGHAI_TOWER]: `上海中心`,
  [keys.SHANGHAI_TOWER_DESC]: `上海中心大厦是一座位于上海浦东陆家嘴的128层、高632米的摩天大楼。它是中国最高的建筑，也是世界第三高的建筑（按建筑顶部高度计算）。自2015年以来，它一直是世界上最高、最大的LEED白金认证建筑。`,
  [keys.PEARL_TOWER]: `东方明珠`,
  [keys.PEARL_TOWER_DESC]: `东方明珠广播电视塔是上海的一座电视塔。它位于浦东区陆家嘴的尖端，濒临黄浦江，与外滩相对，使其成为该地区独特的地标。`,
  [keys.NANJING_ROAD]: `南京路步行街`,
  [keys.NANJING_ROAD_DESC]: `南京路是世界上最繁忙的购物街之一，位于上海。南京路是上海的一条道路，其中东部是上海的主要购物区。`,
  [keys.SHANGHAI_MUSEUM]: `上海博物馆`,
  [keys.SHANGHAI_MUSEUM_DESC]: `上海博物馆是一座位于中国上海市黄浦区人民广场的市立公共博物馆，主要展示古代中国艺术品。它由上海市文化和旅游局资助。博物馆于1996年在其现址重建，以其丰富的珍贵文物收藏而闻名。`,
  [keys.SHANGAI_OCEAN_AQUARIUM]: `上海海洋馆`,
  [keys.SHANGAI_OCEAN_AQUARIUM_DESC]: `这个水族馆包括一条长168米的隧道，带领游客穿越沿海珊瑚礁、开阔海洋、海藻洞穴、鲨鱼湾和珊瑚礁，是世界上最长的类似隧道之一。`,
  [keys.TIANZIFANG]: `田子坊`,
  [keys.TIANZIFANG_DESC]: `田子坊，又称天子坊，是上海法租界地区一个旅游艺术工艺品聚集地，起源于翻新的传统居民区。如今，这里是精品商店、酒吧和餐馆的所在地。`,
  [keys.ZHUJIAJIAO]: `朱家角`,
  [keys.ZHUJIAJIAO_DESC]: `朱家角是上海郊外的一个水乡，建立约有1,700年的历史。同时也发现了可追溯到5,000年前的考古发现。`,
  [keys.HAPPY_VALLEY]: `欢乐谷`,
  [keys.HAPPY_VALLEY_DESC]: `上海欢乐谷是中国上海市松江区的一个游乐园，位于临湖镇。该园于2009年8月16日开业，是欢乐谷主题公园链的第四个分园。该园占地面积863,500平方米。`,
  [keys.SHANGHAI_ZOO]: `上海野生动物园`,
  [keys.SHANGHAI_ZOO_DESC]: `该动物园收容并展出了6000多只动物，其中包括大熊猫、金丝猴、狮子、华南虎、戴胜、黑鹎、大犀鸟和双峰驼等600多种中国动物。`,
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER]: `上海环球金融中心`,
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER_DESC]: `上海环球金融中心是一座位于上海浦东的超高层摩天大楼。其94层设有一个带有玻璃地板的观景平台。`,
  [keys.JINGAN_TEMPLE]: `静安寺`,
  [keys.JINGAN_TEMPLE_DESC]: `静安寺是上海西南京路上的一座密宗唐密佛寺。`,
  [keys.LONGHUA_TEMPLE]: `龙华寺`,
  [keys.LONGHUA_TEMPLE_DESC]: `龙华寺是上海供奉弥勒佛的佛教寺庙。尽管现今的建筑多为后来的重建，但寺庙保留了宋代禅宗寺院的建筑设计。`,
  [keys.WHERE_WHEN_HEADER]: `时间和地点`,
  [keys.WHERE_WHEN]: `婚礼将在上海市黄浦区五里桥街道局门路801号举行。仪式定于2024年9月15日举行。我们将提供往返酒店的交通服务。`,
  [keys.REGISTRY_HEADER]: `礼品与捐赠`,
  [keys.REGISTRY]: `你们出席我们婚礼已经是最好的礼物了。然而，如果你想送给我们一份礼物，我们将遵循中国的传统，接受红包。你可以在婚礼上给我们一个红包。请不要赠送任何实物，因为我们将在婚礼后返回美国。另外，你也可以通过下面的PayPal链接向我们的婚礼基金做出贡献。`,
  [keys.WEDDING_PARTY_HEADER]: `婚礼派对`,
  [keys.WEDDING_PARTY]: `我们感到非常感激，能够在我们婚礼的那一天有这么多朋友和家人支持着我们。我们仍在调整婚礼派对的细节，一旦我们有更多信息，我们将更新这一部分。如果你有兴趣参加婚礼，请告诉我们！`,
  [keys.RSVP_HEADER]: `你可以参加吗？`,
  [keys.RSVP_CONTENT]: `"如果你能来参加我们的婚礼，这对我们来说真的意味着全世界。请通过填写下面的表格告诉我们你是否能够出席。如果你有任何问题，请随时联系我们。请仅在确信能够参加的情况下回复，因为场地空间有限。如果无法参加也没有关系，我们会想念你。"`,
  [keys.NAME]: `你的姓名`,
  [keys.EMAIL]: `邮箱`,
  [keys.GUESTS]: `参加人数`,
  [keys.DATES]: `你将准备在中国呆几天`,
  [keys.COMMENTS]: `任何其他需要我们知道的信息`,
  [keys.SUBMIT]: `提交`,
  [keys.THANK_YOU]: `非常感谢！我们等不急你的到来`,
  [keys.RETURN_HOME]: `返回主页`,
  [keys.SUCCESS_HEADER]: `预约成功`,
  [keys.SUCCESS_CONTENT]: `非常感谢你告诉我们你是否能参加。随着婚礼日期临近，我们会与你保持联系。如果你有任何问题，请随时联系我们。`,
  [keys.TERRACOTTA_ARMY]: `兵马俑`,
  [keys.TERRACOTTA_ARMY_DESC]: `一组描绘中国第一位皇帝秦始皇军队的兵马俑雕塑。这是公元前 210-209 年与皇帝合葬的一种丧葬艺术形式，目的是保护他的来世。`,
  [keys.GIAN_WILD_GOOSE_PAGODA]: `大雁塔`,
  [keys.GIAN_WILD_GOOSE_PAGODA_DESC]: `大雁塔，是位于中国陕西省西安市南部的一座巨大的佛教塔。它建于c。 648 – c.唐代649年，原有五层。"`,
  [keys.GREAT_MOSQUE]: `西安清真寺`,
  [keys.GREAT_MOSQUE_DESC]: `西安清真大寺是中国最大的前现代清真寺之一。该寺始建于唐代公元742年，但据《西安市志》记载，其目前的样子主要建于明朝洪武皇帝统治时期的公元1384年。`,
  [keys.FORTIFICATIONS_OF_XIAN]: `西安城墙`,
  [keys.FORTIFICATIONS_OF_XIAN_DESC]: `西安城墙位于西安，是中国最古老、规模最大、保存最完好的城墙之一。它是洪武皇帝朱元璋统治时期修建的，作为军事防御体系`,
  [keys.XIAN_MUSEUM]: `西安博物馆`,
  [keys.XIAN_MUSEUM_DESC]: `博物馆藏品超过 130,000 件，包括壁画、绘画、陶器、钱币以及青铜器、金银器。它于 2007 年 5 月开业，坐落在一座前文庙内。`,
  [keys.HUAQING_RELIC]: `华清池`,
  [keys.HUAQING_RELIC_DESC]: `华清宫或华清池是位于秦岭三大主峰之一骊山北麓气候温和、风景优美的地区的温泉群。`,
  [keys.TANG_PARADISE]: `大唐芙蓉园`,
  [keys.TANG_PARADISE_DESC]: `大唐芙蓉园是中国西北陕西省省会西安市的一座大型公园。该公园位于唐朝首都长安市早期花园芙蓉园的遗址或附近。`,
  [keys.THINGS_TO_SEE_XIAN]: `在西安可以做的事/游玩的景点`,
  [keys.SHAANXI_HISTORY_MUSEUM]: `陕西历史博物馆`,
  [keys.SHAANXI_HISTORY_MUSEUM_DESC]: `中国最早拥有现代化设施的大型国家博物馆之一，也是最大的国家博物馆之一。`,
  [keys.BELL_TOWER]: `钟楼`,
  [keys.BELL_TOWER_DESC]: `始建于明朝初期的 1384 年，是西安市的象征，也是中国最宏伟的同类建筑之一。钟楼内还藏有几口唐代的大铜钟。塔基呈方形，面积1377平方米。`,
  [keys.PAYPAL_BLURB]: `为了进行婚礼捐赠，请使用下面的安全PayPal链接。我们深感您的善意和慷慨，迫不及待地期待与您共同庆祝这个喜庆的时刻。`,
  [keys.PAYPAL_LINK]: `点击这里进行捐赠`,
};

const nl = {
  [keys.LANDING]: `Landingspagina`,
  [keys.ERROR]: `Er is iets misgegaan.`,
  [keys.TRY_RELOAD]: `Je kunt proberen de pagina opnieuw te laden.`,
  [keys.WELCOME]: `Welkom bij onze bruiloft`,
  [keys.WELCOME_2]: `Wij zijn zo blij dat je er bent!`,
  [keys.OUR_STORY]: `Ons verhaal`,
  [keys.WEDDING_DETAILS]: `Bruiloft details`,
  [keys.TRAVEL]: `Reisinformatie`,
  [keys.RSVP]: `RSVP`,
  [keys.GWEN]: `Gwen`,
  [keys.ZOE]: `Zoe`,
  [keys.GWEN_DEKKER]: `Gwen Dekker`,
  [keys.ZOE_WU]: `Zoe Wu`,
  [keys.AND]: `~ en ~`,
  [keys.WHEN]: `15 september 2024`,
  [keys.WHERE]: `Shanghai, China`,
  [keys.HOW_WE_MET]: `Hoe wij elkaar hebben ontmoet`,
  [keys.HOW_WE_MET_SUBTITLE]: `Verliefde studenten`,
  [keys.HOW_WE_MET_BLURB]: `Wij hebben elkaar ontmoet tijdens Zoe's studie in het buitenland in Seattle. Wij waren allebei jonge studenten die onze weg in de wereld probeerden te vinden. Onze verkering begon in 2015, vlak voordat Zoe na haar eerste schooljaar in de VS terugkeerde naar China voor de zomervakantie. Na een eenzame periode van drie maanden keerde Zoe terug voor haar tweede schooljaar en sindsdien zijn wij onafscheidelijk.`,
  [keys.THE_PROPOSAL]: `Het huwelijksaanzoek`,
  [keys.THE_PROPOSAL_SUBTITLE]: `Een romantische avond in Seaport`,
  [keys.THE_PROPOSAL_BLURB]: `Gwen had een romantisch diner geregeld in een van onze favoriete restaurants in Seaport, Boston. Gwen had een handgemaakt bord gemaakt met de tekst 'Zoe, wil je met me trouwen?', en liet de ober het met het dessert naar buiten brengen. Zoe was zo zenuwachtig dat ze de woorden nauwelijks uit haar mond kon krijgen, maar ze slaagde erin ja te zeggen! We kijken er zo naar uit om de rest van ons leven samen door te brengen.`,
  [keys.OUR_LIFE_TOGETHER]: `Ons leven samen`,
  [keys.OUR_LIFE_TOGETHER_SUBTITLE]: `Een nieuw hoofdstuk`,
  [keys.OUR_LIFE_TOGETHER_BLURB]: `Sinds Zoe en Gwen elkaar voor het eerst ontmoetten in 2015, hebben ze samen veel bereikt. Ze steunden elkaar tijdens hun studie en verhuisden naar Boston voor Gwen's eerste baan. In Boston gingen ze allebei naar de Northeast University voor een graduate studie. Ze kochten samen een mooi huis in Quincy, MA, waar ze wonen met hun twee prachtige honden, Yuki en Lulu.`,
  [keys.TRAVEL_HEADER]: `Internationaal reizen naar China?`,
  [keys.TRAVEL_1]: `Wij kijken er naar uit om je in Shanghai te zien! We begrijpen dat internationaal reizen moeilijk kan zijn, daarom hebben we wat informatie verzameld om je te helpen bij het plannen van je reis. Omdat er zoveel te doen en te zien is tijdens je verblijf, raden wij aan een paar extra dagen te blijven. Als je vóór de bruiloft arriveert, zijn we in Shanghai en willen we graag enkele van de verbazingwekkende bezienswaardigheden met je verkennen! Als je na de bruiloft in de stad bent, nemen we de trein naar Xi'an, waar aan toeristische attracties geen gebrek is. Laat ons vooraf weten wat je plannen zijn, zodat wij dit met je kunnen afstemmen!`,
  [keys.TRAVEL_FROM_INFO]: `Als je vanuit de VS reist, moet je een visum aanvragen. Je kunt een toeristenvisum aanvragen bij jouw plaatselijke Chinese consulaat. Je hebt een kopie van jouw paspoort, een pasfoto en een ingevulde visumaanvraag nodig. Daarnaast heb je een uitnodigingsbrief van ons nodig. Er is waarschijnlijk een particuliere visumdienst bij je in de buurt die kan helpen met het proces, en wij raden aan hiervan gebruik te maken als dit de eerste keer is dat je naar China reist.`,
  [keys.BLANK]: ``,
  [keys.HOTEL_HEADER]: `Hotelinformatie`,
  [keys.HOTEL]: `Als buitenlanders in China zijn er regels over in welk hotel je kunt verblijven. Wij raden een verblijf in Jeurong Hotel aan. Dit hotel ligt dicht bij waar we verblijven, het is vijf minuten lopen van metrolijn één. Wij regelen een shuttle naar de trouwlocatie. Wil je in een ander hotel verblijven, laat het ons dan weten, dan helpen wij jou een hotel te vinden dat aan de eisen voldoet.`,
  [keys.THINGS_TO_SEE_SHANGHAI]: `Bezienswaardigheden in Shanghai`,
  [keys.THE_BUND]: `De Bund`,
  [keys.THE_BUND_DESC]: `De Bund is een gebied aan het water en een beschermd historisch district in het centrum van Shanghai. De Bund siert al bijna 200 jaar de waterkant van de stad. De 19e-eeuwse architectuur benadrukt zijn oorsprong in het tijdperk van de Opiumoorlogen, gevolgd door de komst van westerse banken en handelshuizen in Shanghai.`,
  [keys.YUYUAN_GARDEN]: `Yu Yuan-tuin`,
  [keys.YUYUAN_GARDEN_DESC]: `Yu tuin of Yu Yuan-tuin (Yuyuan Garden) is een uitgestrekte Chinese tuin gelegen naast de tempel van de stadsgod in het noordoosten van de oude stad van Shanghai in het Huangpu-district, Shanghai. Het grenst aan de Yuyuan toeristische markt, het Huxinting Teehuis en de Yu-tuin Bazaar.`,
  [keys.JADE_BUDDHA_TEMPLE]: `Jade Boeddhatempel`,
  [keys.JADE_BUDDHA_TEMPLE_DESC]: `De Jade Boeddhatempel is een boeddhistische tempel in Shanghai. Zoals bij veel moderne Chinese boeddhistische tempels is de huidige tempel gebaseerd op zowel de Pure Land- als de Chan-tradities van het Mahayana-boeddhisme. Het werd in 1882 opgericht met twee jade Boeddhabeelden die over zee vanuit Birma naar Shanghai werden geïmporteerd.`,
  [keys.DISNEYLAND]: `Disneyland`,
  [keys.DISNEYLAND_DESC]: `Shanghai Disneyland Park is een pretpark in Pudong, Shanghai, China, dat deel uitmaakt van het Shanghai Disney Resort. Het park wordt beheerd door Disney Parks, Experiences and Products en Shanghai Shendi Group, via een samenwerking tussen The Walt Disney Company en Shendi.`,
  [keys.SHANGHAI_TOWER]: `Shanghai Tower`,
  [keys.SHANGHAI_TOWER_DESC]: `Shanghai Tower is een megahoge wolkenkrabber van 128 verdiepingen en 632 meter hoog in Lujiazui, Pudong, Shanghai. Het is het hoogste gebouw in China en het derde hoogste gebouw ter wereld qua hoogte tot architectonische top. Het is het hoogste en grootste LEED Platinum-gecertificeerde gebouw ter wereld sinds 2015.`,
  [keys.PEARL_TOWER]: `Pearl Tower`,
  [keys.PEARL_TOWER_DESC]: `De Oriental Pearl Radio & Television Tower is een tv-toren in Shanghai. De ligging op het puntje van Lujiazui in het Pudong-district aan de oever van de Huangpu-rivier, tegenover de Bund, maakt het tot een herkenningspunt in de omgeving.`,
  [keys.NANJING_ROAD]: `Nanjing Road`,
  [keys.NANJING_ROAD_DESC]: `Nanjing Road is een van de drukste winkelstraten ter wereld. Nanjing Road is een weg in Shanghai, waarvan het oostelijke deel het belangrijkste winkelgebied van Shanghai is.`,
  [keys.SHANGHAI_MUSEUM]: `Shanghai Museum`,
  [keys.SHANGHAI_MUSEUM_DESC]: `Het Shanghai Museum is een openbaar museum voor oude Chinese kunst, gelegen aan het Volksplein in het Huangpu-district van Shanghai, China. Het wordt gefinancierd door het Shanghai Municipal Culture and Tourism Bureau. Het werd in 1996 op de huidige locatie herbouwd en staat bekend om zijn grote en zeldzame, culturele collectie.`,
  [keys.SHANGAI_OCEAN_AQUARIUM]: `Shanghai Ocean Aquarium`,
  [keys.SHANGAI_OCEAN_AQUARIUM_DESC]: `Het aquarium omvat een tunnel van 168 meter die bezoekers door een kustrif, open oceaan, een kelpgrot, haaienbaai en een koraalrif voert, en is een van de langste tunnels ter wereld.`,
  [keys.TIANZIFANG]: `Tianzifang`,
  [keys.TIANZIFANG_DESC]: `Tianzifang of Tianzi Fang is een toeristische kunst- en ambachtsenclave die zich heeft ontwikkeld vanuit een gerenoveerde traditionele woonwijk in het Franse concessiegebied van Shanghai. Het is nu de thuisbasis van boetiekjes, bars en restaurants.`,
  [keys.ZHUJIAJIAO]: `Zhujiajiao`,
  [keys.ZHUJIAJIAO_DESC]: `Zhujiajiao is een waterstad aan de rand van Shanghai en werd ongeveer 1700 jaar geleden gesticht. Er zijn ook archeologische vondsten gevonden die 5000 jaar teruggaan.`,
  [keys.HAPPY_VALLEY]: `Happy Valley`,
  [keys.HAPPY_VALLEY_DESC]: `Happy Valley Shanghai is een pretpark in het Songjiang-district, Shanghai, China. Het park ligt in de stad Linhu. Het werd geopend op 16 augustus 2009 en is de vierde installatie van de themaparkketen Happy Valley. Het park beslaat een oppervlakte van 863.500 vierkante meter.`,
  [keys.SHANGHAI_ZOO]: `Shanghai dierentuin`,
  [keys.SHANGHAI_ZOO_DESC]: `De dierentuin herbergt en exposeert meer dan 6.000 dieren, waaronder 600 Chinese dieren, waaronder reuzenpanda's, gouden stompneus apen, leeuwen, Zuid-Chinese tijgers, hop (vogel), zwarte buulbuuls, grote neushoornvogels en Bactrische kamelen.`,
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER]: `Shanghai World Financial Center`,
  [keys.SHANGHAI_WORLD_FINANCIAL_CENTER_DESC]: `Het Shanghai World Financial Center is een supergrote wolkenkrabber in de wijk Pudong in Shanghai. De 94e verdieping heeft een observatiebrug met glazen vloeren.`,
  [keys.JINGAN_TEMPLE]: `Jingan tempel`,
  [keys.JINGAN_TEMPLE_DESC]: `De Jing'an-tempel is een esoterische Tangmi-boeddhistische tempel aan de West Nanjing Road in Shanghai.`,
  [keys.LONGHUA_TEMPLE]: `Longhua tempel`,
  [keys.LONGHUA_TEMPLE_DESC]: `De Longhua-tempel is een boeddhistische tempel gewijd aan de Maitreya Boeddha in Shanghai. Hoewel de meeste van de huidige gebouwen dateren van latere reconstructies, behoudt de tempel het architectonische ontwerp van een klooster uit de Song-dynastie van de Chan-school.`,
  [keys.WHERE_WHEN_HEADER]: `Waar en wanneer`,
  [keys.WHERE_WHEN]: `De bruiloft vindt plaats op Jumen Road, nummer 801, Wuliqiao Street, Huangpu District, Shanghai. De ceremonie zal plaatsvinden op 15 september 2024. Wij zorgen voor vervoer van en naar het hotel.`,
  [keys.REGISTRY_HEADER]: `Kadolijst`,
  [keys.REGISTRY]: `Jouw aanwezigheid op onze bruiloft is het grootste geschenk van allemaal. Als je ons echter een geschenk wilt geven, volgen wij de Chinese traditie van het geven van rode enveloppen. Je kunt ons op de bruiloft een rode envelop geven. Geef ons alstublieft geen fysieke spullen, aangezien we na de bruiloft terugreizen naar de VS. Als alternatief kun je via onderstaande PayPal-link een bijdrage leveren aan ons trouwfonds.`,
  [keys.WEDDING_PARTY_HEADER]: `Bruidspersoneel`,
  [keys.WEDDING_PARTY]: `We zijn zo dankbaar dat we zulke geweldige vrienden en familie hebben die ons steunen op onze trouwdag. We zijn nog bezig met het uitwerken van de details van het huwelijksfeest, maar we zullen deze sectie bijwerken zodra we meer informatie hebben. Als je geïnteresseerd bent om deel uit te maken van het huwelijksfeest, laat het ons dan weten!`,
  [keys.RSVP_HEADER]: `Kom je ook?`,
  [keys.RSVP_CONTENT]: `"Het zou echt heel veel voor ons betekenen als je onze bruiloft zou kunnen bijwonen. Laat ons weten of je aanwezig kunt zijn door het onderstaande formulier in te vullen. Als je vragen hebt, aarzel dan niet om contact met ons op te nemen aan ons. Reageer alleen als je zeker weet dat je aanwezig kunt zijn, aangezien er beperkt ruimte is in de zaal. Mocht je er niet bij kunnen zijn, dan zullen we je missen en hopen we je snel weer te zien!"`,
  [keys.NAME]: `Jouw volledige naam`,
  [keys.EMAIL]: `Jouw email-adres`,
  [keys.GUESTS]: `Aantal gasten`,
  [keys.DATES]: `Hoe lang ben je van plan in China te verblijven?`,
  [keys.COMMENTS]: `Opmerkingen of iets anders dat we moeten weten`,
  [keys.SUBMIT]: `Indienen`,
  [keys.THANK_YOU]: `Hartelijk dank. We zien er naar uit je te zien!`,
  [keys.RETURN_HOME]: `Terug naar landingspagina`,
  [keys.SUCCESS_HEADER]: `Wij hebben je RSVP ontvangen!`,
  [keys.SUCCESS_CONTENT]: `Heel erg bedankt dat je ons laat weten of je kunt komen. We zullen contact met je opnemen naarmate de bruiloft dichterbij komt. Als je vragen hebt, neem dan gerust contact met ons op.`,
  [keys.TERRACOTTA_ARMY]: `Terracottaleger`,
  [keys.TERRACOTTA_ARMY_DESC]: `Een verzameling terracotta sculpturen die de legers van Qin Shi Huang, de eerste keizer van China, voorstellen. Het is een vorm van begrafeniskunst die in 210–209 vGT bij de keizer werd begraven met als doel hem in zijn hiernamaals te beschermen.`,
  [keys.GIAN_WILD_GOOSE_PAGODA]: `Pagoda van de grote wilde gans`,
  [keys.GIAN_WILD_GOOSE_PAGODA_DESC]: `Giant Wild Goose Pagoda of Big Wild Goose Pagoda (Pagoda van de grote wilde gans), is een monumentale boeddhistische pagode in het zuiden van Xi'an, Shaanxi, China. Het werd gebouwd in c. 648 - ca. 649 tijdens de Tang-dynastie en had oorspronkelijk vijf verdiepingen.`,
  [keys.GREAT_MOSQUE]: `Xian grote moskee`,
  [keys.GREAT_MOSQUE_DESC]: `De Grote Moskee van Xi'an is een van de grootste premoderne moskeeën in China. Hoewel de moskee voor het eerst werd gebouwd in het jaar 742 na Christus tijdens de Tang-dynastie, werd de huidige vorm grotendeels gebouwd in 1384 na Christus tijdens het bewind van keizer Hongwu van de Ming-dynastie, zoals vastgelegd in de archieven van de gemeente Xi'an.`,
  [keys.FORTIFICATIONS_OF_XIAN]: `Vestingwerken van Xian`,
  [keys.FORTIFICATIONS_OF_XIAN_DESC]: `Bekend als Xi'an City Wall, in Xi'an, het vertegenwoordigt een van de oudste, grootste en best bewaarde Chinese stadsmuren. Het werd gebouwd onder het bewind van de Hongwu-keizer Zhu Yuanzhang als militair verdedigingssysteem.`,
  [keys.XIAN_MUSEUM]: `Xian museum`,
  [keys.XIAN_MUSEUM_DESC]: `Het museum herbergt meer dan 130.000 voorwerpen, waaronder muurschilderingen, schilderijen, aardewerk, munten, maar ook bronzen, gouden en zilveren voorwerpen. Het werd geopend in mei 2007 en is gehuisvest in een voormalige confucianistische tempel.`,
  [keys.HUAQING_RELIC]: `Huaqing Relic Site`,
  [keys.HUAQING_RELIC_DESC]: `Huaqing Palace of Huaqing Hot Springs is een complex van warmwaterbronnen gelegen in een gebied dat wordt gekenmerkt door mild weer en mooie uitzichten aan de noordelijke voet van de berg Li, een van de drie belangrijkste toppen van het Qin-gebergte.`,
  [keys.TANG_PARADISE]: `Tang Paradise`,
  [keys.TANG_PARADISE_DESC]: `Tang Paradise is een groot park in de stad Xi'an, de hoofdstad van de provincie Shaanxi, Noordwest-China. Het park ligt op of nabij de plaats van een eerder tuincomplex, Furong Garden, in de stad Chang'an, de hoofdstad van de Tang-dynastie.`,
  [keys.THINGS_TO_SEE_XIAN]: `Bezienswaardigheden in Xian`,
  [keys.SHAANXI_HISTORY_MUSEUM]: `Historisch Museum van Shaanxi`,
  [keys.SHAANXI_HISTORY_MUSEUM_DESC]: `Een van de eerste grote staatsmusea met moderne faciliteiten in China, en ook een van de grootste.`,
  [keys.BELL_TOWER]: `Klokkentoren van Xian`,
  [keys.BELL_TOWER_DESC]: `De klokkentoren is  gebouwd in 1384 tijdens de vroege Ming-dynastie. Het is een symbool van de stad Xi'an en een van de grootste in China. De klokkentoren bevat ook verschillende grote, in brons gegoten klokken uit de Tang-dynastie. De torenbasis is vierkant en heeft een oppervlakte van 1.377 m².`,
  [keys.PAYPAL_BLURB]: `Om een bijdrage te leveren, kun je de onderstaande beveiligde PayPal-link gebruiken. Jouw vriendelijkheid en vrijgevigheid worden zeer op prijs gesteld, en we kunnen niet wachten om deze vreugdevolle gelegenheid met je te vieren.`,
  [keys.PAYPAL_LINK]: `Klik hier om een bijdrage te leveren`,
};

const localize = (key, language) => {
  const languageKeys = {
    en,
    cn,
    nl,
  };

  return languageKeys[language][key];
};

export { localize, keys };

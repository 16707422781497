/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import RenderToRoot from 'utils/RenderToRoot';
import OurStory from 'components/OurStory';
import Travel from 'components/Travel';
import Details from 'components/Details';
import Card from 'components/base/Card';
import RSVP from 'components/RSVP';
import Tab from 'components/Tab';
import { keys } from 'utils/Localization';
import useQueryParam from 'hooks/useQueryParam';

const tabs = [
  {
    name: keys.OUR_STORY,
  },
  {
    name: keys.WEDDING_DETAILS,
  },
  {
    name: keys.TRAVEL,
  },
  {
    name: keys.RSVP,
  },
];

const LandingPage = () => {
  const [tab, setTab] = useQueryParam('tab', tabs[0].name);

  return (
    <Card>
      <div className="flex justify-between items-center space-x-4 mx-4 border-b border-neutral-200">
        {tabs.map((t) => (
          <Tab key={t.name} active={tab === t.name} onClick={() => setTab(t.name)} {...t} />
        ))}
      </div>
      {tab === keys.OUR_STORY && <OurStory />}
      {tab === keys.WEDDING_DETAILS && <Details />}
      {tab === keys.TRAVEL && <Travel />}
      {tab === keys.RSVP && <RSVP />}
    </Card>
  );
};

export default RenderToRoot(LandingPage);

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Text from 'components/Text';
import CardBody from 'components/base/CardBody';

import { keys } from 'utils/Localization';
import Label from './base/Label';

const RSVP = () => {
  return (
    <CardBody className="flex flex-col justify-content-start gap-8">
      <div className="flex gap-4 flex-wrap md:flex-nowrap">
        <div className="text-lg md:text-2xl text-left w-full md:w-1/3 font-medium">
          <Text textKey={keys.RSVP_HEADER} />
        </div>
        <div className="text-md md:text-lg w-full md:w-2/3">
          <Text textKey={keys.RSVP_CONTENT} />
        </div>
      </div>
      <form className="flex flex-col gap-4" action="/rsvp" method="POST">
        <Label>
          <Text textKey={keys.NAME} />
        </Label>
        <input
          className="border border-neutral-300 rounded-md p-2"
          type="text"
          name="name"
          placeholder={keys.NAME}
          required
        />
        <Label>
          <Text textKey={keys.EMAIL} />
        </Label>
        <input
          className="border border-neutral-300 rounded-md p-2"
          type="email"
          name="email"
          placeholder={keys.EMAIL}
          required
        />
        <Label>
          <Text textKey={keys.GUESTS} />
        </Label>
        <input
          className="border border-neutral-300 rounded-md p-2"
          type="number"
          name="guests"
          placeholder={keys.GUESTS}
          required
        />
        <Label>
          <Text textKey={keys.DATES} />
        </Label>
        <input
          className="border border-neutral-300 rounded-md p-2"
          type="text"
          name="dates"
          placeholder={keys.DATES}
          required
        />
        <Label>
          <Text textKey={keys.COMMENTS} />
        </Label>
        <textarea
          className="border border-neutral-300 rounded-md p-2"
          name="comments"
          placeholder={keys.COMMENTS}
          required
        />
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          type="submit"
          name="submit"
          value="submit"
        >
          <Text textKey={keys.SUBMIT} />
        </button>
      </form>
    </CardBody>
  );
};

export default RSVP;
